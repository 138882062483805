@import '../../constants/ColorStyles.scss';

.products {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .inner-container {
    width: 90%;
    .title {
      width: 90%;
      font-size: 30px;
      font-weight: 700;
      margin: 0px;
      color: $green;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .first-inner-container {
      width: 100%;
      display: flex;
      @media only screen and (max-width: 1150px) {
        flex-direction: column;
      }
      .first-inner-left {
        width: 50%;
        @media only screen and (max-width: 1150px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }
        .first-inner-left-title {
          color: $green;
          margin: 0;
          font-weight: 500;
        }
        .first-inner-left-text {
          margin: 0px;
          margin-top: 5px;
          .green {
            color: $green;
          }
          margin-bottom: 15px;
        }
        .row {
          display: flex;
          align-items: center;
          padding: 0;
          height: 35px;
          .img {
            margin-top: 2px;
            margin-right: 10px;
            width: 15px;
            height: 15px;
          }
        }
      }
      .first-inner-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
        @media only screen and (max-width: 1150px) {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .img {
          width: 500px;
          cursor: pointer;
          margin-left: 20px;
          @media only screen and (max-width: 1150px) {
            width: 700px;
          }
          @media only screen and (max-width: 750px) {
            margin-left: 0px;
            margin-top: 30px;
            width: 100%;
          }
        }
      }
    }
    .second-inner-container {
      width: 100%;
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;
      @media only screen and (max-width: 1150px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 100%;
      }
      .second-inner-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        @media only screen and (max-width: 1150px) {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .img {
          width: 500px;
          cursor: pointer;
          margin-left: 20px;
          @media only screen and (max-width: 1150px) {
            width: 700px;
          }
          @media only screen and (max-width: 750px) {
            margin-left: 0px;
            margin-top: 30px;
            width: 100%;
          }
        }
      }
      .second-inner-right {
        width: 50%;
        @media only screen and (max-width: 1150px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
        }
        .second-inner-left-title {
          color: $green;
          margin: 0;
          font-weight: 500;
        }
        .second-inner-left-text {
          margin: 0px;
          margin-top: 5px;
          .green {
            color: $green;
          }
          margin-bottom: 15px;
        }
      }
    }
  }
}
