@import '../../constants/ColorStyles.scss';

.technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $dark-blue;
  .inner-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1150px) {
      flex-direction: column;
    }
    .container-left {
      padding-top: 30px;
      padding-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 60%;
      height: 100%;
      @media only screen and (max-width: 1150px) {
        width: 100%;
      }
      .title {
        color: $white;
        width: 100%;
        height: 45px;
        font-size: 30px;
        font-weight: bold;
        margin: 20px;
      }
      .text-container {
        width: 100%;
        padding-bottom: 0px;
        .text {
          line-height: 8px;
          color: $white;
          @media only screen and (max-width: 635px) {
            line-height: 12px;
            font-size: 13px;
            padding-right: 10px;
          }
          .dot {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .container-right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 40%;
      background-color: $dark-blue;
      @media only screen and (max-width: 1150px) {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
      .container-right-img {
        width: 500px;
        height: 380px;
        background-image: url('../../assets/keyboard.gif');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1150px) {
          width: 100%;
        }
      }
    }
  }
}
