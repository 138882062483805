@import '../../constants/ColorStyles.scss';

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin-top: 30px;
    label {
      color: $dark-blue !important;
    }
    .contact-form-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 100%;
      background-color: $green;
      .title {
        font-size: 25px;
        font-weight: bold;
        color: $white;
      }
    }
    .triangle {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid $green;
    }

    .form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .row {
        width: 93%;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 500px) {
          flex-direction: column;
        }
        .input-field {
          display: flex;
          flex-direction: column;
          width: 49%;
          margin-top: 20px;
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
          .label {
            font-size: 14px;
            color: $dark-blue !important;
            margin-bottom: 7px;
            .required {
              margin-left: 5px;
              color: red;
            }
          }
          .form-box {
            padding-left: 7px;
            height: 30px;
            border: 1px solid $green;
            border-radius: 7px;
          }
          .form-box-error {
            padding-left: 7px;
            height: 30px;
            border: 1px solid red;
            border-radius: 7px;
          }
        }
      }
      .second-row {
        width: 93%;
        display: flex;
        justify-content: space-between;
        .input-field {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 20px;
          .label {
            font-size: 14px;
            color: $dark-blue !important;
            margin-bottom: 7px;
          }
          .textarea {
            width: 100%;
            height: 100px;
            border: 1px solid $green;
            border-radius: 7px;
            padding-left: 7px;
            padding-top: 7px;
          }
        }
      }
      .last-row {
        width: 94%;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        @media only screen and (max-width: 500px) {
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: column;
        }
        .checbox-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60%;
          @media only screen and (max-width: 500px) {
            width: 100%;
          }
          .checkbox {
            padding-bottom: 11px;
          }
          .text {
            font-size: 12px;
            color: $dark-blue;
            margin-left: 20px;
            margin-top: 0px;
          }
        }
        .btn {
          border-radius: 8px;
          font-size: 18px;
          font-weight: 600;
          width: 130px;
          height: 50px;
          background-color: $dark-blue;
          color: $white;
          @media only screen and (max-width: 500px) {
            width: 200px;
          }
        }
      }
    }
  }
}
