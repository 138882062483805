@import '../../constants/ColorStyles.scss';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid black;
  background-image: url('../../assets/temporal.png');
  height: 360px;
  @media only screen and (max-width: 1050px) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .inner-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    @media only screen and (max-width: 1050px) {
      flex-direction: column;
    }
    .title {
      color: $white;
      font-weight: bold;
      margin-bottom: 30px;
      @media only screen and (max-width: 1050px) {
        text-align: center;
      }
    }
    .white {
      color: $white;
    }
    .first-container {
      height: 170px;
      @media only screen and (max-width: 1050px) {
        text-align: center;
        height: auto;
        margin-top: 30px;
      }
      .img {
        @media only screen and (max-width: 350px) {
          width: 100%;
        }
      }
    }
    .second-container {
      height: 170px;
      @media only screen and (max-width: 1050px) {
        margin-top: 30px;
        height: auto;
      }
    }
    .third-container {
      height: 170px;
      @media only screen and (max-width: 1050px) {
        text-align: center;
        margin-top: 30px;
        height: auto;
      }
      .social-network {
        @media only screen and (max-width: 1050px) {
          display: flex;
          .white {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
