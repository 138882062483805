@import '../../constants/ColorStyles.scss';

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
  .text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 0px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .title-container {
      width: 10%;
      min-width: 150px;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: $dark-blue;
        margin: 0px;
        margin-top: 10px;
        @media only screen and (max-width: 768px) {
          margin: 0px;
          margin-top: 30px;
        }
      }
    }
    .text-button-container {
      width: 90%;
      color: $dark-blue;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 15%;
      min-width: 250px;
      margin: 10px;
      .img-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px dotted $light-blue;
        padding: 2px;
        margin-bottom: 10px;
        .img {
          width: 150px;
          border-radius: 50%;
        }
      }
      .text {
        height: 30px;
        color: $dark-blue;
        font-weight: bold;
        margin: 0;
        text-align: center;
      }
    }
  }
}
