@import '../../constants/ColorStyles.scss';

.company-references {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url('../../assets/temporal.png');
  .inner-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    .title {
      text-align: center;
      color: $white;
      width: 100%;
      height: 45px;
      font-size: 30px;
      font-weight: bold;
      margin: 0px;
      margin-top: 30px;
      @media only screen and (max-width: 650px) {
        height: 30px;
        font-size: 20px;
      }
      @media only screen and (max-width: 450px) {
        height: 20px;
        font-size: 15px;
      }
    }
    .subtitle {
      color: $white;
      margin-bottom: 40px;
      text-align: center;
      @media only screen and (max-width: 650px) {
        font-size: 12px;
      }
      @media only screen and (max-width: 450px) {
        font-size: 11px;
      }
    }
    .img {
      width: 100%;
      max-width: 1200px;
    }
  }
}
