@import '../../constants/ColorStyles.scss';

.mobile-header-small {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  width: 100%;
  background: white;
  top: 0;
  position: sticky;
  z-index: 100;
  border-bottom: 2px solid $dark-blue;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
  .inner-container {
    width: 90%;
    height: 100%;
    .navigation {
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0px;
      .image {
        height: 30px;
        margin-top: 6px;
        width: 170px;
      }
      .burger-menu {
        margin-top: 5px;
      }
    }
    .context {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 20px;
      .title {
        margin-top: 0px;
        font-size: 30px;
        color: $white;
        font-weight: bold;
      }
      .sub-title {
        width: 80%;
        margin-top: 0px;
        font-size: 24px;
        color: $white;
        margin-bottom: 40px;
        font-weight: 500;
      }
    }
  }
}
