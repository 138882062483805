@import '../../constants/ColorStyles.scss';

.sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  flex-direction: column;
  right: 0;
  top: 72px;
  height: 100vh;
  width: 70%;
  background-color: $white;
  transition: all 0.35s ease-out;
  z-index: 5;
  background-image: url('../../assets/background-normasoft-logo.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow:
    inset 0 0 0 2px rgb(255, 255, 255),
    -7px 10px 10px 1px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 450px) {
    width: 80%;
  }
  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
    width: 100%;
    .nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      .nav-item {
        display: flex;
        justify-content: flex-end;
        padding-top: 7px;
        padding-bottom: 7px;
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        margin-right: 9px;
        padding-right: 10%;
        color: $dark-blue;
        text-decoration: none;
        // margin-top: 15px;
        @media only screen and (max-width: 768px) {
          text-align: start;
          margin-right: 0px;
        }
      }
      .activeClass {
        font-weight: bold;
        width: 100%;
        color: $green;
        background-color: $dark-blue;
      }
    }
  }
}
