@import '../../constants/ColorStyles.scss';

.navigation-inner-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 45%;
  @media only screen and (max-width: 992px) {
    margin-right: 0px;
  }
  .nav-item {
    cursor: pointer;
    position: relative;
    height: 20px;
    margin-left: 15px;
    text-decoration: none;
    border: none;
    z-index: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: $dark-blue;
    background-color: transparent;
  }
  .activeClass {
    font-style: normal;
    color: $green;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
