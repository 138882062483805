@import '../../constants/ColorStyles.scss';

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $dark-blue;
  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    .left-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      padding-top: 40px;
      padding-bottom: 90px;
      z-index: 1;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      .title {
        width: 100%;
        font-size: 30px;
        color: $white;
        font-weight: 200;
        .bold {
          font-weight: bold;
        }
      }
      .subtitle {
        text-align: justify;
        text-justify: inter-word;
        width: 100%;
        font-size: 16px;
        color: $white;
        font-weight: 200;
      }
      .btn-container {
        width: 100%;
        padding-top: 20px;
        .btn {
          cursor: pointer;
          background-color: $green;
          width: 150px;
          height: 40px;
          border: none;
          border-radius: 5px;
          font-weight: 700;
          @media only screen and (max-width: 992px) {
            display: none;
          }
        }
        text-decoration: none;
        .btn-mobile {
          display: none;
          cursor: pointer;
          background-color: $green;
          width: 150px;
          height: 40px;
          border: none;
          border-radius: 5px;
          font-weight: 700;
          @media only screen and (max-width: 992px) {
            display: block;
          }
        }
      }
    }
    .right-container {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media only screen and (max-width: 992px) {
        justify-content: center;
        width: 100%;
        display: none;
      }
      .img {
        width: 100%;
        min-width: 530px;
        height: 450px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media only screen and (max-width: 650px) {
          justify-content: center;
          min-width: auto;
          height: 300px;
          background-size: cover;
        }
        @media only screen and (max-width: 370px) {
          justify-content: center;
          min-width: auto;
          height: 250px;
          background-size: cover;
        }
      }
    }
  }
}
