@import '../../constants/ColorStyles.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  background: white;
  top: 0;
  position: sticky;
  z-index: 100;
  border-bottom: 2px solid $dark-blue;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
      .image-container {
        .image {
          max-width: 250px;
          height: 40px;
        }
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }
  }
}
